<template>
  <div class="article-page container-fluid">
    <feed-detail-banner
      title="Read article"
      :is-public="isPublic"
    />
    <div class="container">
      <el-row :gutter="0">
        <el-col
          :xl="17"
          :lg="17"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <div class="left-side">
            <div
              v-show="loading"
              v-loading="loading"
              :class="{ loading: loading }"
            ></div>
            <div v-if="article">
              <h1 class="title">
                {{ article.title }}
              </h1>
              <div class="userInfo-and-more">
                <el-row>
                  <el-col
                    :xl="12"
                    :md="12"
                    :sm="12"
                    :xs="18"
                  >
                    <div class="grid-content name-date">
                      <div class="grid-content name-date">
                        <img
                          :src="article.author_avatar_url"
                          :alt="article.author"
                        />
                        <span class="text">{{ article.author }}</span>
                        <span class="text date-creation">{{ article.published_at }}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="article-content">
                <div
                  v-if="article.mediaFile && article.mediaFile.is_image"
                  class="media"
                  :style="{ 'background-image' : `url( ${ article.mediaFile.large_thumb } )` }"
                >
                </div>
                <video
                  v-else-if="article.mediaFile"
                  width="100%"
                  controls
                >
                  <source :src="article.mediaFile.path" />
                  Your browser does not support the video tag.
                </video>
                <div class="desc">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="article.content"></p>
                </div>
              </div>
              <!-- FOOTER -->
              <div class="footer">
                <span>Did you enjoy this article?</span>
                <like-button
                  :has-liked="article.likes.has_liked"
                  :likeable-type="article.feed_key"
                  :likeable-id="article.id"
                  @on-liked-toggled="article.likes = $event"
                />
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :xl="7"
          :lg="7"
          class="hidden-md-and-down"
        >
          <sidebar
            v-if="article"
            :tags="article.tags.data"
            :feed-key="article.feed_key"
            :is-public="isPublic"
            :is-related="true"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'
import accountApi from '@/modules/account/services/account.api'
import FeedDetailBanner from '@/modules/feed/components/feed-detail-banner'
import Sidebar from '@/modules/feed/components/sidebar'
import LikeButton from '@/modules/feed/components/like-button'

export default {
  components: {
    FeedDetailBanner,
    Sidebar,
    LikeButton,
  },

  props: {
    isPublic: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    article: null,
    loading: false,
    likeLoading: false,
  }),

  watch: {
    '$route.params.id' (id) {
      this.article = null
      this.fetchArticle()
    }
  },

  created() {
    this.fetchArticle()
  },

  methods: {
    fetchArticle() {
      this.loading = true

      feedApi.article(this.$route.params.id, this.isPublic)
        .then(response => {
          this.loading = false
          this.article = response.data

          if (!this.isPublic) {
            this.updatePoints()
          }
        })
        .catch(() => {
          this.$router.push({ name: 'feed-feed' })
        })
    },
    updatePoints() {
      accountApi
        .gamers()
        .then(response => {
          this.$store.commit("UPDATE_POINTS", response.data);
        })
    },
    likeUnlike(hasLiked) {
      if (!this.store.state.isLogged) {
        this.$router.push({ name: 'auth-login' })
      }

      this.likeLoading = true

      feedApi[hasLiked ? 'unlike' : 'like']({
        "module": this.article.feed_key,
        "entity": this.article.feed_key,
        "id": `${this.article.id}`
      }).then(response => {
        this.likeLoading = false
        this.article.likes = response.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.article-page {
  @media (max-width: 991px) {
    overflow: hidden;
  }
  div.el-loading-spinner {
    margin-top: -28px;
  }
  .left-side {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -90%;
      width: 90%;
      height: 100%;
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        background-color: #fff;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }
    .loading {
      min-height: 300px;
    }
    .title {
      margin-top: 0;
      padding-top: 28px;
      @media (max-width: 575px) {
        font-size: 36px;
        line-height: 40px;
      }
      @media (max-width: 440px) {
        font-size: 24px;
      }
    }
    //min-height: 1350px;
    background-color: $white-color;
    padding: 0 65px 42px 0;
    @media (max-width: 991px) {
      padding: 0;
    }
    .userInfo-and-more {
      padding: 14px 0 12px 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: $border-color;
      @media (max-width: 375px) {
        .el-col {
          width: 100%;
        }
      }
      .name-date {
        display: flex;
        height: 41px;
        line-height: 41px;
        img {
            width: 38px;
            height: 38px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: inline-block;
            border-radius: 50%;
            margin-left: 0;
        }
        .text {
          display: inline-block;
          margin-left: 10px;
          font-size: 12px;
          font-weight: bold;
          vertical-align: text-bottom;
        }
        .date-creation {
          position: relative;
          padding-left: 30px;
          &:before {
            content: '|';
            position: absolute;
            right: 78px;
            color: #979797;
          }
        }
      }
      .share-btn {
        text-align: right;
        padding-right: 35px;
        button {
          background-color: transparent;
          vertical-align: sub;
          img  {
            vertical-align: middle;
            border-radius: inherit;
          }
          span {
            margin-left: 5px;
            vertical-align: middle;
          }
        }
      }
    }
    .article-content {
      margin-top: 20px;
      .desc {
        img {
          width: 100% !important;
        }
      }
      .media {
        height: 372px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        img {
          width: 100%;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 56px;
      padding-bottom: 45px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 768px) {
  .article-page {
    .left-side {
      width: 100%;
    }
    .footer {
      text-align: center;
      .el-col {
        margin-top: 20px;
      }
      .like-btn {
        text-align: center !important;
      }
    }
  }
}

@media screen and (max-width: 674px) {
  .article-page {
    .left-side {
      padding: 0 5px;
      .userInfo-and-more {
        .name-date {
          .name {
            text-align: left;
          }
          .date {
            border: none;
            text-align: right;
          }
        }
        .share-btn {
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .article-page {
    .left-side {
      .userInfo-and-more {
        text-align: center;
        .name-date {
          display: inherit;
        }
      }
    }
  }
}
</style>
